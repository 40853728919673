<template>
  <div class="task_allinfo_container">
    <div class="task_item">
      <div class="task_title">描述内容</div>
      <div class="info" v-html="descriptionList.remark"></div>
    </div>
    <div class="task_item">
      <div class="task_title">完成时间</div>
      <div class="info">{{descriptionList.timeLimit}}天</div>
    </div>
    <div class="task_item">
      <div class="task_title">任务关闭时间</div>
      <div class="info">{{descriptionList.deadline}}</div>
    </div>
    <div class="task_item">
      <div class="task_title">任务发布时间</div>
      <div class="info">{{descriptionList.publishedAt}}</div>
    </div>
    <div class="task_item">
      <div class="task_title">任务奖励</div>
      <div class="info">{{descriptionList.amount + '元' }}</div>
    </div>
    <div class="task_item"
      v-if="descriptionList.status === TASK_STATUS.SUCCESSFUL || descriptionList.status === TASK_STATUS.WAITING_FOR_REWARDS">
      <div class="task_title">完成奖励</div>
      <div class="info">{{completionReward + '元'}}</div>
    </div>
    <div class="task_item"
      v-if="descriptionList.status === TASK_STATUS.SUCCESSFUL || descriptionList.status === TASK_STATUS.WAITING_FOR_REWARDS">
      <div class="task_title">说明理由</div>
      <div class="info">{{descriptionList.completionReason}}</div>
    </div>
    <div class="task_item">
      <div class="task_title">任务级别</div>
      <span
        :style="{
          [TASK_LEVEL.COMMON]: 'color: #67c23a;',
          [TASK_LEVEL.GENERAL]: 'color: #e6a23c;',
          [TASK_LEVEL.COMPLEX]: 'color: #f56c6c;',
          [TASK_LEVEL.DIFFICULTY]: 'color: #909399;'
        }[descriptionList.level]">
        {{
          {
            [TASK_LEVEL.COMMON]: '普通',
            [TASK_LEVEL.GENERAL]: '一般',
            [TASK_LEVEL.COMPLEX]: '复杂',
            [TASK_LEVEL.DIFFICULTY]: '困难'
          }[descriptionList.level]
        }}
      </span>
    </div>
    <div class="task_item">
      <div class="task_title">任务要求</div>
      <div class="info" v-html="descriptionList.require"></div>
    </div>
    <div class="task_item">
      <div class="task_title">相关附件</div>
      <file-list
        :fileList="descriptionList.attachments"
        style="width: 370px;">
      </file-list>
    </div>
    <div class="task_item" v-if="isMaterials">
      <div class="task_title">完结材料</div>
      <end-materials
        :info="descriptionList">
      </end-materials>
    </div>
    <div class="task_item" v-if="isUpload">
      <div class="task_title">完结材料</div>
      <upload
        :fileList.sync="descriptionList.fileList"
        :taskingId.sync = descriptionList.taskingId>
      </upload>
    </div>
  </div>
</template>

<script>
import FileList from '@/components/upload/file-list.vue'
import EndMaterials from '@/components/task-detail/end-materials/index.vue'
import Upload from '@/components/upload/upload.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    FileList,
    EndMaterials,
    Upload
  },
  props: {
    isMaterials: {
      type: Boolean,
      default: false
    },
    isUpload: {
      type: Boolean,
      default: false
    },
    // 任务详情
    descriptionList: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    completionReward () {
      if (this.descriptionList.completeness) {
        return (this.descriptionList.amount * (this.descriptionList.completeness / 100)).toFixed(2)
      }
      return ''
    },
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    },
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    }
  }
}
</script>

<style lang="scss" scoped>
.task_allinfo_container {
  width: 100%;
  height: 100%;
  padding: 30px 50px 0 50px;
  overflow: auto;
  .task_item {
    display: flex;
    margin-bottom: 20px;
    word-wrap:break-word;
    word-break:break-all;
    .task_title {
      width: 100px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 500;
      color: #909399;
    }
    .info {
      font-size: 14px;
      white-space:pre-wrap;
      font-weight: 500;
      color: #303133;
      flex:1;
    }
  }
}
</style>
