<template>
  <div class="wrapper" v-if="info.username">
    <div class="info_container">
      <div class="avatar">{{String(info.username).charAt(0)}}</div>
      <div class="user_info">
        <div class="name">{{info.username}}</div>
        <div class="phone">{{info.mobile}}</div>
      </div>
    </div>
    <div>
      <file-list
        v-if="info.userAttachment"
        :fileList="fileList">
      </file-list>
    </div>
  </div>
</template>

<script>
import FileList from '@/components/upload/file-list.vue'

export default {
  components: {
    FileList
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    fileList () {
      return this.info.userAttachment ? [this.info.userAttachment] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 360px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  .info_container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .avatar {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background: #3473E6;
      color: white;
    }
    .user_info {
      margin-left: 10px;
      .name {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: bold;
        color: #606266;
      }
      .phone {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #909399;
      }
    }
  }
}
</style>
