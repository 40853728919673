<template>
  <div class="title_container">

    <div class="back_to_task" @click="backToTaskHall">
      <i class="el-icon-arrow-left"></i>
      <span>返回上页</span>
    </div>

    <!-- 任务信息 -->
    <div class="task_title">
      <div
        class="title"
        :title="descriptionList.title">
        {{descriptionList.title}}
      </div>
      <div class="time">{{descriptionList.deadline}}</div>
    </div>

    <!-- 企业信息 -->
    <div class="company_container">
      <div class="avatar">科</div>
      <div class="company_info">
        <div class="company_name">
          <div class="title">{{companyInfo.name}}</div>
          <div class="verified">
            <img src="@/assets/images/certification.png" alt="已认证" />
          </div>
        </div>
        <div class="contact_methods">
          <span
            class="phone"
            :title="companyInfo.telephone">
            {{companyInfo.telephone}}
          </span>
          <span
            class="email"
            :title="companyInfo.email">
            {{companyInfo.email}}
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    backToPath: {
      type: String,
      default: '/home'
    },
    descriptionList: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyInfo () {
      return this.descriptionList.company ? this.descriptionList.company : {}
    }
  },
  methods: {
    backToTaskHall () {
      this.$router.push(this.backToPath)
    }
  }
}
</script>

<style lang="scss" scoped>
.title_container {
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E4E7ED;
  padding: 0 20px;
  .back_to_task {
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    margin-right: 30px;
    color: #606266;
    cursor: pointer;
  }
  .task_title {
    flex: 1;
    .title {
      width: 500px;
      height: 28px;
      margin-bottom: 6px;
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      color: #303133;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #909399;
    }
  }
  .company_container {
    height: 100px;
    padding-right: 43px;
    display: flex;
    border-left: 1px solid #E4E7ED;
    align-items: center;
    .avatar {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 24px;
      margin: 0 20px;
      border-radius: 50%;
      background: #5DD5C8;
      text-align: center;
      color: white;
    }
    .company_info {
      .company_name {
        height: 30px;
        display: flex;
        margin-bottom: 6px;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #303133;
          margin-right: 20px;
        }
      }
      .contact_methods {
        display: flex;
        align-items: center;
        height: 30px;
        font-size: 14px;
        color: #909399;
        .phone {
          width: 92px;
          margin-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .email {
          width: 195px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
