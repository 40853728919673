<template>
  <el-upload
    style="width: 370px;"
    :action="uploadUrl"
    :on-success="handleSuccess"
    :on-error="handleError"
    :on-remove="handleRemove"
    :limit="1"
    :file-list="$fileList">
    <el-button
      v-if="show"
      size="small"
      type="primary">
      点击上传
    </el-button>
  </el-upload>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    fileList: {
      type: Array,
      default: () => ([])
    },
    taskingId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    $fileList: {
      get () {
        return this.fileList
      },
      set (value) {
        this.$emit('update:fileList', value)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    },
    uploadUrl: {
      get () {
        return process.env.VUE_APP_API_BASE_URL + 'api/v1/media/put'
      }
    },
    show () {
      if (this.$fileList.length === 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // 上传成功的回调
    handleSuccess (response, file) {
      this.userCommit(response, file)
    },
    // 删除文件
    handleRemove (file, fileList) {
      const index = this.$fileList.findIndex(item => {
        return item.uid === file.uid
      })
      console.log(index)
      this.$fileList.splice(index, 1)
    },
    // 上传失败的回调
    handleError () {
      this.$message.error('上传失败1')
    },
    // 用户上传文件
    userCommit (response, file) {
      api.userTaskingsCommit(this.taskingId, {
        attachmentJson: { ...response }
      }).then(res => {
        if (res.data.code === 0) {
          console.log(response, file)
          this.$fileList.push({
            uid: file.uid,
            name: file.name,
            ...response.data
          })
          this.$message.success('上传成功')
        } else {
          this.$message.error('上传失败2')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>
